import React, { useMemo, useState } from 'react'
import { get, isEmpty, upperFirst } from 'lodash'

import { RequirementsModal } from 'civic-champs-shared/question-sets/components'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { invitationTypeToActionLookup } from 'civic-champs-shared/question-sets/types'
import HeaderWrapper from 'civic-champs-shared/core/HeaderWrapper'
import StepContainer from 'civic-champs-shared/core/StepContainer'
import { useAdminLogin } from 'invite/hooks/useAdminLogin'
import useIssueLoginToken from 'civic-champs-shared/api/hooks/useIssueLoginToken'

import Loading from '../../../core/Loading'
import { useAcceptOrRejectInvite, useSignUp, useUpdateProfile, useUpdateOrg } from '../../hooks'
import Welcome from './Welcome'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepZero from './StepZero'
import Review from './Review'
import { skipNextAuthEvent } from 'auth/useUserStatus'

export default function InviteForm(props) {
  const loginAdmin = useAdminLogin()
  const [issueLoginToken] = useIssueLoginToken({})
  const { invite, children, initialStep = 1 } = props
  const steps = useMemo(() => {
    const steps = ['Log-in', 'Additional Information', 'Verify']
    if (initialStep === 0) steps.unshift('Organization')
    return steps
  }, [initialStep])
  const [acceptOrRejectInvite] = useAcceptOrRejectInvite(invite)
  const [updateProfile] = useUpdateProfile(invite)
  const [organization, setOrganization] = useState(invite.organization)
  const signUp = useSignUp(invite)
  const [step, setStep] = useState(initialStep)
  const [updateOrg] = useUpdateOrg(invite)
  const [password, setPassword] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [profile, setProfile] = useState({
    givenName: upperFirst(get(invite, 'person.givenName') || ''),
    familyName: upperFirst(get(invite, 'person.familyName') || ''),
    email: get(invite, 'person.email') || '',
    phoneNumber: get(invite, 'person.phoneNumber') || '',
    homePhoneNumber: get(invite, 'person.homePhoneNumber') || '',
    olderThanThirteen: false,
  })
  const [confirmedProfile, setConfirmedProfile] = useState({})
  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)

  const handleRequirements = async user => {
    try {
      const done = await fulfillRequirementsPrompt({
        person: user,
        filters: {
          personId: user.id,
          invitationToken: invite.secureToken,
          action: get(invitationTypeToActionLookup, invite.invitationType, 'user_sign_up'),
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
          excludeCredentials: false,
        },
        isAdmin: false,
        organizationId: invite.organization.id,
      })
      return done
    } catch (error) {
      console.error('error', error)
    }
    return true
  }

  const onSubmitStepZero = ({ address, name, website, timeZone }) => {
    const org = {
      address: {
        line1: address.line1,
        line2: address.line2,
        state: address.state,
        city: address.city,
        country: address.country,
        zip: address.zip,
        gps: address.geofencing.location.coordinates,
      },
      name,
      website,
      timeZone,
    }
    setOrganization(org)
    updateOrg(org).then(() => setStep(1))
  }
  const onSubmitStepOne = profile => {
    setPassword(profile.password)
    setStep(2)
  }

  const onSubmitStepTwo = async profile => {
    setProfile(profile)
    if (await handleRequirements(invite.person)) {
      setStep(3)
    }
  }
  const handleBack = () => {
    setStep(step => step - 1)
  }

  const onSubmitStepReview = async () => {
    setIsSubmitting(true)
    try {
      // important that 'updateProfile' goes first, so that we can mark the email or phone as 'verified',
      // which is checked during the preSignup hook
      const { olderThanThirteen, ...rest } = profile
      const updatedProfile = await updateProfile(rest)
      await signUp({ ...updatedProfile, password })
      // import that 'acceptOrRejectInvite' goes after 'signUp' in case 'signUp' fails,
      // as the user cannot do anything once an invite is accepted
      await acceptOrRejectInvite('accept')

      if (initialStep === 0) {
        skipNextAuthEvent('signIn')
        await loginAdmin(rest.email, password)
        const { token } = await issueLoginToken({ organizationId: invite.organization.id })

        window.location = `${process.env.REACT_APP_ADMIN_SITE}/?token=${token}`

        // avoid enabling action buttons before redirect by awaiting promise that never resolves
        await new Promise(() => {})
      } else {
        setConfirmedProfile(profile)
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <HeaderWrapper title="Create Account">
      {!invite ? (
        <Loading />
      ) : !isEmpty(confirmedProfile) ? (
        <Welcome invite={invite}>
          <div className="invite-header">
            <p style={{ textAlign: 'center' }}>
              {organization.isMentoringCustomer ? (
                <>
                  {confirmedProfile.givenName}, thank you for signing up for Mentoring Works: Powered by Civic Champs.
                  Now you can easily record your mentor/mentee interactions for {organization.name} by downloading the
                  Mentoring Works mobile app.
                </>
              ) : (
                <>
                  {confirmedProfile.givenName}, thank you for signing up for Civic Champs. Now you can easily track your
                  hours for {organization.name} in your community.
                </>
              )}
            </p>
          </div>
        </Welcome>
      ) : (
        <>
          {step !== 3 && children}
          {step === 3 && (
            <div className="invite-header">
              <p style={{ textAlign: 'center' }}>
                Does everything look correct?
                <br />
                Please take a moment to verify your account details.
              </p>
            </div>
          )}
          <StepContainer steps={steps} activeStep={step - initialStep}>
            {step === 0 && <StepZero invite={invite} onSubmit={onSubmitStepZero}></StepZero>}
            {step === 1 && (
              <StepOne
                onBack={initialStep === 0 ? handleBack : undefined}
                password={password}
                invite={invite}
                onSubmit={onSubmitStepOne}
              ></StepOne>
            )}
            {step === 2 && (
              <StepTwo
                onChange={setProfile}
                onBack={handleBack}
                invite={invite}
                profile={profile}
                onSubmit={onSubmitStepTwo}
              />
            )}
            {step === 3 && (
              <Review
                onBack={handleBack}
                isSubmitting={isSubmitting}
                organization={organization}
                profile={profile}
                invite={invite}
                initialStep={initialStep}
                onSubmit={onSubmitStepReview}
              />
            )}
          </StepContainer>
        </>
      )}
    </HeaderWrapper>
  )
}
