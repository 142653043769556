import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { stepThreeSchema } from '../../schemas'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { CheckboxField, PhoneField, PasswordField } from 'civic-champs-shared/formik/components'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { FormikEffect } from 'civic-champs-shared/question-sets/components'

function StepThree(props) {
  const { onSubmit, email, phoneNumber, personId, classes } = props
  const isAboveSmall = isWidthUp('sm', props.width)
  return (
    <>
      <div className="invite-header">
        <p>Please tell us a little bit more about yourself.</p>
      </div>
      <Formik
        initialValues={{
          email,
          phoneNumber,
          personId,
        }}
        validationSchema={stepThreeSchema}
        onSubmit={values => onSubmit(stepThreeSchema.cast(values))}
        validateOnBlur={true}
        validateOnChange={true}
      >
        {({ handleSubmit, isSubmitting, isValid, values, errors }) => (
          <form>
            <FormikEffect formik={{ values, errors }} />
            <Grid container spacing={2} direction="column" justify="center" alignItems="center">
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    fullWidth={!isAboveSmall}
                    component={TextField}
                    label="First Name"
                    name="givenName"
                    variant="outlined"
                    autoCapitalize="on"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    fullWidth={!isAboveSmall}
                    component={TextField}
                    name="familyName"
                    label="Last Name"
                    variant="outlined"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    fullWidth={!isAboveSmall}
                    component={TextField}
                    label="Email"
                    name="email"
                    variant="outlined"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    fullWidth={!isAboveSmall}
                    component={PhoneField}
                    label="Mobile Phone"
                    name="phoneNumber"
                    variant="outlined"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    fullWidth={!isAboveSmall}
                    component={PhoneField}
                    label="Home Phone"
                    name="homePhoneNumber"
                    variant="outlined"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    component={PasswordField}
                    name="password"
                    label="Password"
                    variant="outlined"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} direction="row" justify="center" alignItems="center">
                <Grid item>
                  <Field
                    component={CheckboxField}
                    name="olderThanThirteen"
                    label="I am older than 13"
                    labelstyle={{ font: '18px/24px Open Sans' }}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Button
                  type="submit"
                  className="submit button"
                  disabled={isSubmitting || !isValid}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default withWidth()(StepThree)
