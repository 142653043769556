export const formatPhoneNumberForDatabase = value => {
    if (!value) {
        // will cause issues if the field isn't nullable()
        return value
    }
    // stripping away all '+' and '-'
    const interimValue = value.replace(/\D/g, '')
    // ensuring +1 is always there
    const prefix = interimValue.length === 11 ? '+' : '+1'
    return prefix + interimValue
}
