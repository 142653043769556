import React, { useMemo } from 'react'
import { Grid } from '@material-ui/core'
import { isAndroid, isIOS } from 'react-device-detect'
import { LinkButton } from '../../../common/components/LinkButton'

import appStoreQR from '../../../images/CivicChampsAppQRCode.png'

const {
  REACT_APP_CC_APPLE_STORE_LINK,
  REACT_APP_CC_ANDROID_STORE_LINK,
  REACT_APP_MW_APPLE_STORE_LINK,
  REACT_APP_MW_ANDROID_STORE_LINK,
} = process.env

export default function Welcome({ organization, children }) {
  const linkComponent = useMemo(() => {
    if (isIOS) {
      return (
        <LinkButton
          link={organization.isMentoringCustomer ? REACT_APP_MW_APPLE_STORE_LINK : REACT_APP_CC_APPLE_STORE_LINK}
          description="Get the app by following the link below."
        />
      )
    } else if (isAndroid) {
      return (
        <LinkButton
          link={organization.isMentoringCustomer ? REACT_APP_MW_ANDROID_STORE_LINK : REACT_APP_CC_ANDROID_STORE_LINK}
          description="Get the app by following the link below."
        />
      )
    } else {
      return (
        <Grid container direction="row" alignItems="center" justify="center">
          <Grid item container xs={2} alignItems="center" justify="center" direction="column">
            <Grid item>
              <img alt="QR code for link to app store" width="200px" src={appStoreQR} />
            </Grid>
          </Grid>
        </Grid>
      )
    }
  }, [organization.isMentoringCustomer])

  return (
    <>
      {children}
      {/* temporary hide this, see here https://www.notion.so/civic-champs/Differentiate-Mentoring-Customers-for-onboarding-app-download-message-b45cd3fe3f0e433683606002ae7fa305?pvs=4
        <Grid container direction="column" justify="center" alignItems="center">
          {linkComponent}
        </Grid>
      */}
    </>
  )
}
