import { useCallback, useMemo } from 'react'
import { useFetch } from '../../api/hooks'

export const useFetchMentoringMeeting = () => {
  // @ts-ignore
  const [request, { loading, called, error, result }] = useFetch(
    'Error Fetching Questionnaire.  Please refresh the page and try again',
  )

  const fetchMentoringMeeting = useCallback(
    async meetingId => {
      // @ts-ignore
      return request({
        method: 'get',
        url: `/mentorship-meetings/${meetingId}?includeQuestionSet=1`,
      })
    },
    [request],
  )

  return useMemo(
    () => [
      fetchMentoringMeeting,
      {
        loading,
        called,
        error,
        result: result || null,
      },
    ],
    [fetchMentoringMeeting, loading, called, error, result],
  )
}
