import { useFetch } from "civic-champs-shared/api/hooks";
import { useCallback } from "react"

const useAcceptOrRejectInvite = (invite) => {
    const [request, status] = useFetch('Error accepting invitation')
    const secureToken = invite && invite.secureToken
    const acceptOrRejectInvite = useCallback( (type) => {
        return request({
            method: 'post',
            url: `/invitations/${secureToken}/${type}`,
        })
    }, [secureToken, request])

    return [acceptOrRejectInvite, status]
}

export default useAcceptOrRejectInvite
