import { useLocation } from 'react-router-dom'
import React from 'react'

export default function useShouldHideHeader() {
  const { search, pathname } = useLocation()

  return React.useMemo(() => {
    const patterns = ['^/invite/']
    const masterPattern = new RegExp(patterns.join('|'))
    // @ts-ignore
    return !!parseInt(new URLSearchParams(search).get('hideHeader')) || masterPattern.test(pathname)
  }, [search, pathname])
}
