import React from 'react'
import { Button, Grid } from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { Link } from '@material-ui/core'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'

import { stepOneSchema } from '../../schemas'
import { PhoneField, PasswordField } from 'civic-champs-shared/formik/components'

function Login(props) {
  const { group, organization, user, onSubmit, onForgotPassword, goToStep, classes } = props
  const { email, phoneNumber } = user
  const isAboveSmall = isWidthUp('sm', props.width)

  return (
    <>
      <div className="invite-header">
        <p style={{ textAlign: 'center' }}>
          Please login to continue your {group.name} application for {organization.name}
        </p>
      </div>
      <Formik
        initialValues={{
          email,
          phoneNumber,
          password: null,
        }}
        validationSchema={stepOneSchema}
        onSubmit={values => onSubmit(stepOneSchema.cast(values))}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form>
            <Grid container spacing={2} direction="column" justify="center" alignItems="center">
              {phoneNumber ? (
                <Grid item container spacing={2} justify="center">
                  <Grid item>
                    <Field
                      fullWidth={!isAboveSmall}
                      component={PhoneField}
                      label="Phone Number"
                      name="phoneNumber"
                      variant="outlined"
                      className={classes.input}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item container spacing={2} justify="center">
                  <Grid item>
                    <Field
                      fullWidth={!isAboveSmall}
                      component={TextField}
                      label="Email"
                      name="email"
                      variant="outlined"
                      className={classes.input}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field component={PasswordField} name="password" label="Password" variant="outlined" />
                </Grid>
              </Grid>

              <Grid item container spacing={2} justify="center">
                <Button
                  type="submit"
                  className="submit button"
                  disabled={isSubmitting || (!values.email && !values.phoneNumber) || !values.password}
                  onClick={handleSubmit}
                >
                  Login
                </Button>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Link
                  className={classes.forgotLink}
                  onClick={async () => {
                    const nextStep = await onForgotPassword({ email: values.email, phoneNumber: values.phoneNumber })
                    goToStep(nextStep)
                  }}
                >
                  Forgot Password
                </Link>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default withWidth()(Login)
