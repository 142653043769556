import yup from '../utils/yup'

export const stepOneSchema = yup
  .object({
    email: yup.string().email().trim().lowercase().nullable(),
    phoneNumber: yup.string().phoneNumber().nullable(),
  })
  .atLeastOneOf(['email', 'phoneNumber'])

export const stepThreeSchema = yup
  .object({
    givenName: yup.string().trim().label('first name').required('First Name is required'),
    familyName: yup.string().trim().label('last name').required('Last Name is required'),
    email: yup.string().email().trim().lowercase().nullable(),
    phoneNumber: yup.string().phoneNumber().nullable(),
    homePhoneNumber: yup.string().phoneNumber().nullable(),
    olderThanThirteen: yup.bool().oneOf([true], 'Users must be thirteen years of age or older'),
    personId: yup.number().nullable().notRequired(),
  })
  .atLeastOneOf(['email', 'phoneNumber'])

export const resetPasswordSchema = yup.object({
  contact: yup.string().required(),
  code: yup.string().required().matches(/\d{6}/, 'Must be exactly 6 digits'),
  password: yup.string().min(8).label('Password').required('Password is required'),
  passwordConfirm: yup
    .string()
    .label('Confirm Password')
    .oneOf([yup.ref('password'), null], 'Password does not match')
    .required('Retype Password is required'),
})

export const verifyCodeSchema = yup.object({
  code: yup.string().required().matches(/\d{6}/, 'Must be exactly 6 digits'),
})
