import React from 'react'
import { Grid } from '@material-ui/core'
import { resetPasswordSchema } from '../../schemas'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import { PasswordField } from 'civic-champs-shared/formik/components'
import { ContainedButton, OutlinedButton } from 'civic-champs-shared/core/'

export function ChangePassword(props) {
  const { onSubmit, onClose, classes, emailOrPhoneNumber } = props

  return (
    <>
      <h1 className={classes.header}>Create a New Password</h1>
      <p>
        Just enter the 6-digit verification code we've sent to {emailOrPhoneNumber} (it can sometimes take a minute or
        two to arrive), along with a new password for your account.
      </p>

      <Formik
        initialValues={{
          emailOrPhoneNumber,
          code: '',
          password: '',
          passwordConfirm: '',
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={values => onSubmit(resetPasswordSchema.cast(values))}
        validateOnBlur
        validateOnChange
      >
        {({ dirty, isValid }) => (
          <Form className={classes.form}>
            <Grid container spacing={2} direction="column" justify="center" alignItems="center">
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    component={TextField}
                    name="code"
                    label="Verification Code"
                    className={classes.input}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    component={PasswordField}
                    name="password"
                    variant="outlined"
                    label="Password"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    component={PasswordField}
                    name="passwordConfirm"
                    variant="outlined"
                    label="Confirm Password"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid container justify="space-between" xs={12}>
                <OutlinedButton className="button" onClick={onClose}>
                  Start Over
                </OutlinedButton>

                <ContainedButton
                  variant="contained"
                  disabled={!dirty || !isValid}
                  type="submit"
                  className="submit button"
                >
                  Save My New Password
                </ContainedButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ChangePassword
