import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './config/config-amplify'
import App from './App'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

const theme = createMuiTheme({
  props: {
    MuiTypography: {
      variantMapping: {
        h3: 'label',
      },
    },
  },
})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
  document.getElementById('root'),
)

