import React from 'react'
import { Grid } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'

import { emailOrPhoneNumberSchema } from '../../schemas'
import { OutlinedButton, ContainedButton } from 'civic-champs-shared/core/'

export function ForgotPassword(props) {
  const { onSubmit, onClose, classes, emailOrPhoneNumber = '' } = props

  return (
    <>
      <h1 className={classes.header}>Forgot Your Password?</h1>
      <p>
        Don't worry! Just enter the phone number or email address you used to create your account, and we will send you
        verification code to reset your password.
      </p>

      <Formik
        initialValues={{
          emailOrPhoneNumber,
        }}
        validationSchema={emailOrPhoneNumberSchema}
        onSubmit={values => onSubmit(emailOrPhoneNumberSchema.cast(values))}
        validateOnBlur
        validateOnChange
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form className={classes.form}>
            <Grid container spacing={2} direction="column" justify="center" alignItems="center">
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    component={TextField}
                    label="Phone number or email"
                    name="emailOrPhoneNumber"
                    variant="outlined"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid container justify="space-between" xs={12}>
                <OutlinedButton className="button" onClick={onClose}>
                  Start Over
                </OutlinedButton>

                <ContainedButton
                  type="submit"
                  className="submit button"
                  disabled={isSubmitting || !isValid}
                  onClick={handleSubmit}
                >
                  Send verification code
                </ContainedButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ForgotPassword
