import * as yup from 'yup'
import { formatPhoneNumberForDatabase } from './format'
import { upperFirst, trim } from 'lodash'

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89AB][0-9a-f]{3}-[0-9a-f]{12}$/i

yup.addMethod(yup.string, 'uuid', function () {
  return this.matches(UUID_REGEX, 'is not a UUID')
})

yup.addMethod(yup.string, 'phoneNumber', function () {
  return this.transform(function (value) {
    return formatPhoneNumberForDatabase(value)
  }).test({
    name: 'phoneNumber',
    message: 'Must be a valid US phone number',
    exclusive: true,
    test: value => !value || /([+][1][2-9][0-9]{9})/.test(value),
  })
})

yup.addMethod(yup.string, 'phoneNumberUS', function (message) {
  return this.test({
    name: 'phoneNumberUS',
    message: message || '${path} must be a valid US phone number',
    exclusive: true,
    test: value => !value || /^([+]?1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/.test(value),
  })
})

yup.addMethod(yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: '${path} must have at least one of: ${keys}',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: value => !value == null || list.some(key => value[key] != null),
  })
})

yup.addMethod(yup.string, 'name', function () {
  return this.transform(function (value) {
    return value && trim(upperFirst(value))
  })
})

yup.addMethod(yup.string, 'oneOfSchemas', function (schemas, msg) {
  return this.test({
    name: 'oneOfSchemas',
    message: msg,
    test: value => {
      if (Array.isArray(schemas) && schemas.length > 1) {
        return schemas.map(schema => schema.isValidSync(value)).some(Boolean)
      } else {
        throw new TypeError('Incorrect array of schemas')
      }
    },
    exclusive: false,
  })
})

export default yup
