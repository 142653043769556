import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'

const useUpdateOrg = invite => {
  const [request, status] = useFetch('Error saving organization')
  const secureToken = invite && invite.secureToken
  const updateProfile = useCallback(
    org => {
      return request({
        method: 'put',
        url: `/invitations/${secureToken}/organization`,
        config: {
          body: org,
        },
      })
    },
    [secureToken, request],
  )

  return [updateProfile, status]
}

export default useUpdateOrg
