import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'

const useGetApplicationGroup = () => {
  const [request, { loading, called, result, error }] = useFetch()

  const getGroup = useCallback(
    (orgSlugName, groupId) => request({ url: `/applications/${orgSlugName}/groups/${groupId}`, publicApi: true }),
    [request],
  )

  return [getGroup, { loading: loading || !called, result, error }]
}

export default useGetApplicationGroup
