import React from 'react'
import InviteForm from './InviteForm'
import { upperFirst } from 'lodash'
import { getOrganizationTypeName } from '../../utils'

// TODO: fill in real text
export default function CustomGroupInvite(props) {
  const { invite } = props
  const { organization } = invite
  return (
    <InviteForm invite={invite}>
      <div className="invite-header">
        <h2>Hi {upperFirst(invite.recipientGivenName)}</h2>
        <p style={{ textAlign: 'center' }}>
          {organization.name} has invited you to join {getOrganizationTypeName(organization)} to help them track your
          volunteer hours. Please complete our simple sign up below and we will get you connected to your account
        </p>
      </div>
    </InviteForm>
  )
}
