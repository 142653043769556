import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'

const useGetSchedulerOrgLink = invite => {
  const [request, status] = useFetch('Error getting organization')
  const { organization } = invite
  const getSchedulerOrgLink = useCallback(() => {
    return request({
      url: `/scheduler-links/${organization.id}`,
    })
  }, [request, organization.id])

  return [getSchedulerOrgLink, status]
}

export default useGetSchedulerOrgLink
