import usePost from './usePost'

export default function useIssueLoginToken({
  successMessage,
  errorMessage,
}: {
  successMessage?: string
  errorMessage?: string
}) {
  return usePost<any, any>({
    successMessage,
    errorMessage,
    configMapper: ({ organizationId, expiresIn = '180m' }: { organizationId: number; expiresIn?: string }) => ({
      url: `/login-token`,
      config: { body: { organizationId, expiresIn } },
    }),
  })
}
