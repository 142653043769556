import { useEffect, useMemo } from 'react'
import { Hub } from 'aws-amplify'
import * as moment from 'moment'

import format from './format'

const { REACT_APP_ENV, REACT_APP_INTERCOM_APP_ID } = process.env

const safeCall = (method, payload) => {
  window.Intercom && window.Intercom(method, payload)
}

export const useIntercomWidget = (user, organization) => {
  // Initialization
  useEffect(() => {
    // See integration instruction here https://www.intercom.com/help/en/articles/168-install-intercom-in-your-product-for-logged-in-users
    if (window.Intercom) {
      return
    }

    window.intercomSettings = {
      api_base: 'https://api-iam.intercom.io',
      app_id: REACT_APP_INTERCOM_APP_ID,
    }

    const script = document.createElement('script')
    script.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/bedere7b';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`
    document.body.appendChild(script)

    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signOut':
          window.Intercom('shutdown')
          break
        default:
      }
    })
  }, [])

  useEffect(() => {
    if (!user || !window.Intercom) {
      return
    }

    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: REACT_APP_INTERCOM_APP_ID,
      name: format.name(user),
      user_id: `${REACT_APP_ENV}:${user.id}`,
      created_at: moment(user.createdAt).unix(),
      user_hash: user.intercomHash,
      email: user.email,
      email_verified: user.emailVerified,
      organization: organization?.name,
    })
  }, [organization, user])

  return useMemo(
    () => ({
      hideWidget: () =>
        safeCall('update', {
          hide_default_launcher: true,
        }),
      showWidget: () =>
        safeCall('update', {
          hide_default_launcher: false,
        }),
      // we need to call this every time a URL changes,
      // see https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
      pingWidget: () => safeCall('update', { last_request_at: parseInt(new Date().getTime() / 1000) }),
    }),
    [],
  )
}

export default useIntercomWidget
