import React from 'react'
import { Grid } from '@material-ui/core'
import { Link } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'

import { loginSchema } from '../../schemas'
import { PasswordField } from 'civic-champs-shared/formik/components'
import { ContainedButton } from 'civic-champs-shared/core/'

export function Login(props) {
  const { onSubmit, onForgotPassword, classes } = props

  return (
    <Formik
      initialValues={{
        emailOrPhoneNumber: '',
        password: '',
      }}
      validationSchema={loginSchema}
      onSubmit={values => {
        onSubmit(loginSchema.cast(values))
      }}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <Form className={classes.form}>
          <Grid container spacing={2} direction="column" justify="center" alignItems="center">
            <Grid item container spacing={2} justify="center">
              <Grid item>
                <Field
                  component={TextField}
                  label="Login"
                  placeholder="Enter your phone number or email"
                  name="emailOrPhoneNumber"
                  variant="outlined"
                  className={classes.input}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2} justify="center">
              <Grid item>
                <Field
                  component={PasswordField}
                  name="password"
                  label="Password"
                  placeholder="Enter your password"
                  variant="outlined"
                  className={classes.input}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={2} justify="center">
              Forgot your password?
              <Link className={classes.forgotLink} onClick={onForgotPassword}>
                Reset your password
              </Link>
            </Grid>

            <Grid item container spacing={2} justify="center">
              <ContainedButton
                type="submit"
                className="submit button"
                disabled={isSubmitting || !isValid}
                onClick={handleSubmit}
              >
                Sign In
              </ContainedButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default Login
