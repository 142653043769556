import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'

export const useUnsubscribe = () => {
  const [request] = useFetchRefactored<void>()

  return async (token: string) => {
    await request({
      method: 'put',
      url: `/user/unsubscribe`,
      config: {
        body: { token },
      },
    })
  }
}
