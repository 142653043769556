import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { useGetInvite } from '../hooks'
import useInviteComponent from '../hooks/useInviteComponent'
import Loading from '../../civic-champs-shared/core/Loading'

export default function Invites() {
  const { params } = useRouteMatch()
  const { token } = params
  useEffect(() => {
    token && localStorage.setItem('secureToken', `invitation:${token}`)
  }, [token])
  const [getInvite, { loading, result }] = useGetInvite()
  const { invitation, status, canProceed, person } = result || {}
  useEffect(() => {
    getInvite(token)
  }, [getInvite, token])

  const inviteComponent = useInviteComponent({ loading, invitation, status, canProceed, person })

  return loading ? <Loading /> : inviteComponent
}
