import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { Formik } from 'formik'
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@material-ui/core'

import withWidth from '@material-ui/core/withWidth'

function StepTwo(props) {
  const { onSubmit, persons, goToStep } = props
  const name = 'selectedOption'
  const contacts = [...persons, { id: -1 }]

  const formatContact = ({ givenName, familyName, email, phoneNumber }) => {
    return `${email || phoneNumber} - ${givenName} ${familyName}`
  }

  return (
    <>
      <div className="invite-header">
        <p style={{ textAlign: 'center' }}>
          One or more of the contacts you provided are already associated with activity in our platform. Please select
          one of the following if that activity is yours.
        </p>
      </div>
      <Formik
        initialValues={{
          [name]: null,
        }}
        onSubmit={async values => {
          const nextStep = await onSubmit(values[name])
          goToStep(nextStep)
        }}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
          <form>
            <Grid container spacing={2} direction="column" justify="center" alignItems="center">
              <Grid item container spacing={2} justify="center">
                <FormControl component="fieldset">
                  <RadioGroup
                    name={name}
                    value={values.selectedOption}
                    onChange={event => {
                      setFieldValue(name, Number.parseInt(event.currentTarget.value))
                    }}
                  >
                    {contacts.map(({ id, ...rest }) => (
                      <FormControlLabel
                        value={id}
                        control={<Radio />}
                        label={id === -1 ? 'None' : formatContact(rest)}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item container spacing={2} justify="center">
                <Button
                  type="submit"
                  className="submit button"
                  disabled={isSubmitting || !values.selectedOption}
                  onClick={handleSubmit}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default withWidth()(StepTwo)
