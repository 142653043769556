import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 70px',
    margin: 'auto',
    maxWidth: '38%',
    border: '3px solid #4c8de8',
    borderRadius: '15px',
    [theme.breakpoints.down('md')]: {
      border: 0,
      maxWidth: 'unset',
      padding: '0 16px',
    },
  },
}))

const { REACT_APP_HELP_DESK_EMAIL } = process.env

export default function InviteExpiredSentNew({ invite }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <h1 style={{ textAlign: 'center' }}>Oops! Your account activation link has expired</h1>
      <p>
        The account activation link you received has expired, but don't worry, we're sending another to{' '}
        {invite.recipientEmail || invite.recipientPhoneNumber} right away.
      </p>
      <p>
        If you don't receive the new activation link shortly, or are still having trouble setting up you account, you
        can contact your volunteer coordinator, or send a message to the Civic Champs help desk by emailing{' '}
        <a href={`mailto:${REACT_APP_HELP_DESK_EMAIL}`}>{REACT_APP_HELP_DESK_EMAIL}</a>
      </p>
    </div>
  )
}
