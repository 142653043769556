import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Auth } from 'aws-amplify'

import { useErrorNotification, useSuccessNotification } from 'civic-champs-shared/api/hooks'

import { Loading } from 'civic-champs-shared/core'
import { useGetSchedulerOrgLink } from '../../hooks'
import { Login } from './Login'
import { ForgotPassword } from './ForgotPassword'
import { ChangePassword } from './ChangePassword'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 70px',
    margin: 'auto',
    maxWidth: '35%',
    border: '3px solid #4c8de8',
    borderRadius: '15px',
    [theme.breakpoints.down('md')]: {
      border: 0,
      maxWidth: 'unset',
      padding: '0 16px',
    },
  },
  form: {
    width: '100%',
  },
  forgotLink: {
    marginLeft: '10px',
    marginBottom: '5px',
    cursor: 'pointer',
  },
  input: {
    width: '300px',
  },
  button: {
    lineHeight: '20px',
  },
  header: {
    textAlign: 'center',
  },
}))

const Steps = {
  Initital: 1,
  ResetPassword: 2,
  ChangePassword: 3,
  Login: 4,
}

export default function InviteAlreadyAcceptedLogin({ invite }) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(Steps.Initital)
  const [emailOrPhoneNumber, setEmailOrPhoneNumber] = useState('')
  const showError = useErrorNotification()
  const showSuccess = useSuccessNotification(true)

  const [getSchedulerOrgWithLink] = useGetSchedulerOrgLink(invite)

  const onLogin = useCallback(
    async ({ emailOrPhoneNumber, password }) => {
      try {
        setLoading(true)
        await Auth.signIn(emailOrPhoneNumber, password)
        const { link } = await getSchedulerOrgWithLink()
        window.location.href = link
      } catch (err) {
        showError('There was a problem signing in', err)
      } finally {
        setLoading(false)
      }
    },
    [getSchedulerOrgWithLink, showError],
  )

  const onResetPassword = useCallback(
    async ({ emailOrPhoneNumber }) => {
      try {
        setLoading(true)
        setEmailOrPhoneNumber(emailOrPhoneNumber)
        await Auth.forgotPassword(emailOrPhoneNumber)
        setStep(Steps.ChangePassword)
      } catch (err) {
        showError('There was a problem resetting your password', err)
      } finally {
        setLoading(false)
      }
    },
    [showError],
  )

  const onChangePassword = useCallback(
    async ({ code, password }) => {
      try {
        setLoading(true)
        await Auth.forgotPasswordSubmit(emailOrPhoneNumber, code, password)
        await Auth.signIn(emailOrPhoneNumber, password)

        showSuccess('Your password has been updated')
        setStep(Steps.Login)
      } catch (err) {
        showError('There was a problem changing your password', err)
      } finally {
        setLoading(false)
      }
    },
    [emailOrPhoneNumber, showError],
  )

  if (loading) {
    return <Loading size={60} />
  }

  return (
    <div className={classes.container}>
      {[Steps.Initital, Steps.Login].includes(step) && (
        <>
          {step === Steps.Initital && (
            <>
              <h1 style={{ textAlign: 'center' }}>Oops! Your account has already been activated</h1>
              <p>
                An account registered to {invite.recipientEmail || invite.recipientPhoneNumber} has already been
                created, so you should be able to sign into Civic Champs with it, using that{' '}
                {invite.recipientEmail ? 'email address' : invite.recipiendPhoneNumber} as your login.
              </p>
            </>
          )}
          <Login classes={classes} onSubmit={onLogin} onForgotPassword={() => setStep(Steps.ResetPassword)} />
        </>
      )}
      {step === Steps.ResetPassword && (
        <ForgotPassword classes={classes} onSubmit={onResetPassword} onClose={() => setStep(Steps.Initital)} />
      )}
      {step === Steps.ChangePassword && (
        <ChangePassword
          classes={classes}
          emailOrPhoneNumber={emailOrPhoneNumber}
          onSubmit={onChangePassword}
          onClose={() => setStep(Steps.Initital)}
        />
      )}
    </div>
  )
}
