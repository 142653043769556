import React from 'react'
import { cloneDeep } from 'lodash'
import { stepTwoSchema } from '../../schemas'
import { Field, Formik } from 'formik'
import { FormikEffect } from 'civic-champs-shared/question-sets/components'
import StyledOutlinedTextField from 'civic-champs-shared/formik/components/StyledOutlinedTextField'
import StyledOutlinedPhoneField from 'civic-champs-shared/formik/components/StyledOutlinedPhoneField'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import { useStepStyles } from 'invite/hooks/useStepStyles'
import StyledOnboardingCheckboxField from 'civic-champs-shared/formik/components/StyledOnboardingCheckboxField'

function StepTwo(props) {
  const { invite, onSubmit, onBack, profile, onChange } = props
  const classes = useStepStyles()
  return (
    <>
      <Formik
        initialValues={cloneDeep(profile)}
        validationSchema={stepTwoSchema}
        onSubmit={values => onSubmit(stepTwoSchema.cast(values))}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleSubmit, isSubmitting, isValid, dirty, values, errors }) => (
          <form>
            <FormikEffect
              onChange={state => {
                onChange(state.values)
              }}
              formik={{ values, errors }}
            />
            <div className={classes.container}>
              <label className={classes.title}>First Name</label>
              <div className={classes.field}>
                <Field
                  fullWidth
                  component={StyledOutlinedTextField}
                  name="givenName"
                  autoCapitalize="on"
                  variant="outlined"
                  marginless
                />
              </div>
              <label className={classes.title}>Last Name</label>
              <div className={classes.field}>
                <Field fullWidth component={StyledOutlinedTextField} name="familyName" variant="outlined" marginless />
              </div>
              <label className={classes.title}>Email</label>
              <div className={classes.field}>
                <Field
                  fullWidth
                  component={StyledOutlinedTextField}
                  name="email"
                  variant="outlined"
                  marginless
                  disabled={invite.method === 'email'}
                />
              </div>
              <label className={classes.title}>Mobile Phone</label>
              <div className={classes.field}>
                <Field
                  fullWidth
                  component={StyledOutlinedPhoneField}
                  name="phoneNumber"
                  variant="outlined"
                  marginless
                  disabled={invite.method === 'phone'}
                />
              </div>
              <label className={classes.title}>Home Phone</label>
              <div className={classes.field}>
                <Field
                  fullWidth
                  component={StyledOutlinedPhoneField}
                  name="homePhoneNumber"
                  variant="outlined"
                  marginless
                />
              </div>
              <div className={classes.field} style={{ marginBottom: '30px' }}>
                <Field
                  component={StyledOnboardingCheckboxField}
                  name="olderThanThirteen"
                  label="I am 13 years or older"
                />
              </div>
              <div className={classes.buttons}>
                <ContainedButton
                  type="submit"
                  disabled={isSubmitting || !values.olderThanThirteen}
                  onClick={handleSubmit}
                >
                  Next
                </ContainedButton>
                {onBack && (
                  <OutlinedButton onClick={onBack} disabled={isSubmitting}>
                    Back
                  </OutlinedButton>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default StepTwo
