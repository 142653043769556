import React, { useState } from 'react'
import { FormControl, InputLabel, InputAdornment, IconButton, FormHelperText } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import StyledOutlinedTextField from './StyledOutlinedTextFieldComponent'

function StyledOutlinedPasswordField({ field, form, label, placeholder, className, fullWidth, ...props }) {
  const { value, onChange, name } = field
  const [showPassword, setShowPassword] = useState(false)
  const helperText = form.errors[name]
  const error = !!helperText
  const labelOrPlaceholder = label || placeholder

  return (
    <FormControl error={error} variant="outlined" fullWidth={fullWidth}>
      {labelOrPlaceholder && <InputLabel htmlFor={name}>{labelOrPlaceholder}</InputLabel>}
      <StyledOutlinedTextField
        {...props}
        id={name}
        className={className}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => {
                  setShowPassword(value => !value)
                }}
                onMouseDown={e => {
                  e.preventDefault()
                }}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityIcon style={{ color: '#0F5DB5' }} />
                ) : (
                  <VisibilityOffIcon style={{ color: '#0F5DB5' }} />
                )}
              </IconButton>
            </InputAdornment>
          ),
          labelWidth: labelOrPlaceholder ? labelOrPlaceholder.length * 10 : 0,
        }}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default StyledOutlinedPasswordField
