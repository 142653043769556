import React from 'react'
import { FormControl } from '@material-ui/core'
import { orgSchema } from '../../schemas'
import { Field, Formik } from 'formik'
import StyledOutlinedTextField from 'civic-champs-shared/formik/components/StyledOutlinedTextField'
import { AddressField } from 'civic-champs-shared/formik/components/AddressField'
import StyledSelect, { MenuItem } from 'civic-champs-shared/formik/components/StyledSelect'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { useStepStyles } from 'invite/hooks/useStepStyles'

function StepZero(props) {
  const {
    invite: {
      organization: { id: organizationId, address, ...initialValues },
    },
    onSubmit,
    children,
  } = props
  const classes = useStepStyles()
  return (
    <>
      {children}
      <Formik
        initialValues={{
          ...initialValues,
          address: {
            ...address,
            line1: `${address.postalCode} ${address.line1}, ${address.city}, ${address.state},`,
            zip: address.postalCode,
          },
        }}
        validationSchema={orgSchema}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form>
            <div className={classes.container}>
              <label className={classes.title}>Name</label>
              <div className={classes.field}>
                <Field fullWidth component={StyledOutlinedTextField} name="name" variant="outlined" marginless />
              </div>
              <label className={classes.title}>Website</label>
              <div className={classes.field}>
                <Field fullWidth component={StyledOutlinedTextField} name="website" variant="outlined" marginless />
              </div>
              <label className={classes.title}>Time Zone</label>
              <div className={classes.field}>
                <FormControl variant="outlined" fullWidth>
                  <Field
                    fullWidth
                    variant="outlined"
                    component={StyledSelect}
                    select={true}
                    name="timeZone"
                    inputProps={{
                      id: 'timeZone',
                    }}
                    marginless
                  >
                    <MenuItem value={'America/New_York'}>Eastern Time</MenuItem>
                    <MenuItem value={'America/Chicago'}>Central Time</MenuItem>
                    <MenuItem value={'America/Denver'}>Mountain Time</MenuItem>
                    <MenuItem value={'America/Phoenix'}>Mountain Standard Time</MenuItem>
                    <MenuItem value={'America/Los_Angeles'}>Pacific Time</MenuItem>
                    <MenuItem value={'America/Anchorage'}>Alaska Time</MenuItem>
                    <MenuItem value={'Pacific/Honolulu'}>Hawaii Time</MenuItem>
                  </Field>
                </FormControl>
              </div>
              <label className={classes.title}>Address</label>
              <div className={classes.field} style={{ marginBottom: '30px' }}>
                <Field
                  fullWidth
                  loadLocationFromAddress
                  component={AddressField}
                  name="address"
                  showRadius={false}
                  placeholder="Address"
                  vertical={true}
                />
              </div>

              <div className={classes.buttons}>
                <ContainedButton type="submit" disabled={isSubmitting} onClick={handleSubmit}>
                  Next
                </ContainedButton>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default StepZero
