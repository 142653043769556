import React from 'react'
import styled from 'styled-components'
import './style.scss'
import { PALETTE_COLORS, TextColorType } from '../../constants/colors'
import { ReactComponent as LogoIcon } from './icons/logo.svg'
import { ReactComponent as OpenEyeIcon } from './icons/open_eye.svg'
import { ReactComponent as ClosedEyeIcon } from './icons/closed_eye.svg'
import { ReactComponent as FacebookIcon } from './icons/facebook.svg'
import { ReactComponent as GoogleIcon } from './icons/google.svg'
import { ReactComponent as LocationMarkIcon } from './icons/location-mark.svg'
import { ReactComponent as AvatarIcon } from './icons/avatar.svg'
import { ReactComponent as LoupeIcon } from './icons/loupe.svg'
import { ReactComponent as Twitter } from './icons/twitter.svg'
import { ReactComponent as Time } from './icons/time.svg'
import { ReactComponent as XMarkIcon } from './icons/x_mark.svg'
import { ReactComponent as PolygonIcon } from './icons/polygon.svg'
import { ReactComponent as MenuIcon } from './icons/menu.svg'
import { ReactComponent as CloseFilterIcon } from './icons/close-filter.svg'
import { ReactComponent as UserIcon } from './icons/user.svg'
import { ReactComponent as CopyIcon } from './icons/copy.svg'
import { ReactComponent as ContactIcon } from './icons/contact.svg'
import { ReactComponent as ReflectionIcon } from './icons/reflection.svg'
import { ReactComponent as EmoticonExcitedIcon } from './icons/emoticon-excited.svg'
import { ReactComponent as EmoticonHappyIcon } from './icons/emoticon-happy.svg'
import { ReactComponent as EmoticonFrownIcon } from './icons/emoticon-frown.svg'
import { ReactComponent as EmoticonSadIcon } from './icons/emoticon-sad.svg'
import { ReactComponent as CloudIcon } from './icons/cloud.svg'

interface IconProps {
  fill?: string
  width?: string | number
  height?: string | number
  style?: React.CSSProperties
}

export type Icon =
  | 'logo'
  | 'open_eye'
  | 'closed_eye'
  | 'google'
  | 'facebook'
  | 'locationMark'
  | 'avatar'
  | 'loupe'
  | 'twitter'
  | 'time'
  | 'polygon'
  | 'x_mark'
  | 'menu'
  | 'close_filter'
  | 'user'
  | 'copy'
  | 'contact'
  | 'reflection'
  | 'emoticon_excited'
  | 'emoticon_happy'
  | 'emoticon_frown'
  | 'emoticon_sad'
  | 'cloud'

const renderIcons = (props: IconProps): { [key in Icon]: JSX.Element } => {
  return {
    logo: <LogoIcon {...props} />,
    open_eye: <OpenEyeIcon {...props} />,
    closed_eye: <ClosedEyeIcon {...props} />,
    facebook: <FacebookIcon {...props} />,
    google: <GoogleIcon {...props} />,
    locationMark: <LocationMarkIcon {...props} />,
    avatar: <AvatarIcon {...props} />,
    loupe: <LoupeIcon {...props} />,
    twitter: <Twitter {...props} />,
    time: <Time {...props} />,
    polygon: <PolygonIcon {...props} />,
    menu: <MenuIcon {...props} />,
    close_filter: <CloseFilterIcon {...props} />,
    user: <UserIcon {...props} />,
    x_mark: <XMarkIcon {...props} />,
    copy: <CopyIcon {...props} />,
    contact: <ContactIcon {...props} />,
    reflection: <ReflectionIcon {...props} />,
    emoticon_excited: <EmoticonExcitedIcon {...props} />,
    emoticon_happy: <EmoticonHappyIcon {...props} />,
    emoticon_frown: <EmoticonFrownIcon {...props} />,
    emoticon_sad: <EmoticonSadIcon {...props} />,
    cloud: <CloudIcon {...props} />,
  }
}

interface IconComponentProps {
  name: Icon
  style?: React.CSSProperties
  disabled?: boolean
  width?: string | number
  height?: string | number
  color?: TextColorType
  customColor?: string
  className?: string
}

const DEFAULT_ICON_WIDTH = 40
const DEFAULT_ICON_HEIGHT = 40

export function IconComponent(props: IconComponentProps): JSX.Element {
  const { color, style, width, height, customColor, className } = props

  const StyledIconComponent = styled.div`
    pointer-events: ${props.disabled ? 'none' : 'auto'};
  `

  const setDefaultIconProps: IconProps = {
    fill: customColor ? customColor : PALETTE_COLORS.textColors[color || 'primaryLight'],
    width: width || DEFAULT_ICON_WIDTH,
    height: height || DEFAULT_ICON_HEIGHT,
    style,
  }

  return (
    <StyledIconComponent className={`icon-component ${className || ''}`}>
      {renderIcons(setDefaultIconProps)[props.name]}
    </StyledIconComponent>
  )
}

export default IconComponent
