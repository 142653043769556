import { useCallback, useMemo } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { QuestionSetResponseWithRequired } from 'civic-champs-shared/question-sets/types'
import { Status } from 'civic-champs-shared/api/hooks'

export const useFetchQuestionnairePreview = () => {
  const [request, status] = useFetchRefactored<QuestionSetResponseWithRequired>({
    errorMessage: 'Error Fetching Preview',
  })
  const fetchQuestionnairePreview = useCallback(
    async (uuid: string) => {
      return request({
        method: 'get',
        url: `/questionnaire-preview/${uuid}`,
      })
    },
    [request],
  )
  return useMemo(() => [fetchQuestionnairePreview, status], [fetchQuestionnairePreview, status]) as [
    (uuid: string) => Promise<QuestionSetResponseWithRequired>,
    Status<QuestionSetResponseWithRequired>,
  ]
}
