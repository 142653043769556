import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { useUnsubscribe } from 'unsubscribe/hooks/useUnsubscribe'
import { makeStyles } from '@material-ui/styles'
import cn from 'classnames'

export const useStyles = makeStyles((theme: any) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '30px 70px',
    margin: 'auto',
    maxWidth: '38%',
    border: '3px solid #4c8de8',
    borderRadius: '15px',
    [theme.breakpoints.down('md')]: {
      border: 0,
      maxWidth: 'unset',
      padding: '0 16px',
    },
  },
  title: {
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: 500,
    lineHeight: '36px',
  },
  text: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  link: {
    color: '#0F5DB5',
  },
}))

export const Unsubscribe = () => {
  const { params } = useRouteMatch<{ token: string }>()
  const unsubscribe = useUnsubscribe()
  const classes = useStyles()

  useEffect(() => {
    unsubscribe(params.token)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Your contact information is set to Do&nbsp;Not&nbsp;Contact</h1>
      <p className={classes.text}>
        You will no longer receive any messages from Civic Champs. This includes all outbound communication from any
        organization that utilizes Civic Champs for volunteer management.
      </p>
      <p className={classes.text}>
        You can remove yourself from the Do Not Contact list through the Civic Champs mobile app, or by contacting{' '}
        <a className={cn(classes.text, classes.link)} href={`mailto:${process.env.REACT_APP_HELP_DESK_EMAIL}`}>
          {process.env.REACT_APP_HELP_DESK_EMAIL}
        </a>
      </p>
    </div>
  )
}
