import { useCallback } from 'react'

import { useFetch } from '../../civic-champs-shared/api/hooks'

export const EXISTING_USER = 'EXISTING_USER'

const useSelfApply = () => {
  const [request, status] = useFetch('Error applying group. Please contact group administrators.')

  const selfApply = useCallback(
    async ({ orgSlugName, groupId, type = EXISTING_USER, userId }) => {
      return request({
        method: 'post',
        url: `/applications/${orgSlugName}/groups/${groupId}/members`,
        config: {
          body: {
            type,
            userId,
          },
        },
      })
    },
    [request],
  )

  return [selfApply, status]
}

export default useSelfApply
