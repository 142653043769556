import yup from '../utils/yup'

export const orgSchema = yup.object({
  name: yup.string().required('Name is required'),
  website: yup.string().required('Website is required'),
  address: yup.object({
    line1: yup.string().required('Address is required'),
  }),
})

export const passwordSchema = yup.string().min(8).required('Password is required')

export const stepOneSchema = yup.object({
  password: passwordSchema,
  passwordConfirm: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Password does not match')
    .required('Retype Password is required'),
})

export const stepTwoSchema = yup
  .object({
    givenName: yup.string().trim().label('first name').required('First Name is required'),
    familyName: yup.string().trim().label('last name').required('Last Name is required'),
    email: yup.string().email().trim().lowercase().nullable(),
    phoneNumber: yup.string().phoneNumber().nullable(),
    homePhoneNumber: yup.string().phoneNumber().nullable(),
    olderThanThirteen: yup.bool().oneOf([true], 'Users must be thirteen years of age or older'),
  })
  .atLeastOneOf(['email', 'phoneNumber'])

export const emailOrPhoneStringSchema = yup
  .string()
  .oneOfSchemas(
    [yup.string().email().required(), yup.string().phoneNumberUS().required()],
    'Email or phone number is required',
  )

export const loginSchema = yup.object({
  emailOrPhoneNumber: emailOrPhoneStringSchema,
  password: passwordSchema,
})

export const emailOrPhoneNumberSchema = yup.object({
  emailOrPhoneNumber: emailOrPhoneStringSchema,
})

export const resetPasswordSchema = yup.object({
  emailOrPhoneNumber: emailOrPhoneStringSchema,
  code: yup.string().required().matches(/\d{6}/, 'Must be exactly 6 digits'),
  password: passwordSchema,
  passwordConfirm: yup
    .string()
    .label('Confirm Password')
    .oneOf([yup.ref('password'), null], 'Password does not match')
    .required('Retype Password is required'),
})

export const verifyCodeSchema = yup.object({
  code: yup.string().required().matches(/\d{6}/, 'Must be exactly 6 digits'),
})
