import React from 'react'
import { Button, Grid } from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { Field, Formik } from 'formik'
import { TextField } from 'formik-material-ui'

import { stepOneSchema } from '../../schemas'
import { PhoneField } from 'civic-champs-shared/formik/components'

function ForgotPassword(props) {
  const { user, onSubmit, classes } = props
  const { email, phoneNumber } = user
  const isAboveSmall = isWidthUp('sm', props.width)

  return (
    <>
      <Formik
        initialValues={{
          email,
          phoneNumber,
          password: null,
        }}
        validationSchema={stepOneSchema}
        onSubmit={values => onSubmit(stepOneSchema.cast(values))}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <form>
            <Grid container spacing={2} direction="column" justify="center" alignItems="center">
              {phoneNumber ? (
                <Grid item container spacing={2} justify="center">
                  <Grid item>
                    <Field
                      fullWidth={!isAboveSmall}
                      component={PhoneField}
                      label="Phone Number"
                      name="phoneNumber"
                      variant="outlined"
                      className={classes.input}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid item container spacing={2} justify="center">
                  <Grid item>
                    <Field
                      fullWidth={!isAboveSmall}
                      component={TextField}
                      label="Email"
                      name="email"
                      variant="outlined"
                      className={classes.input}
                    />
                  </Grid>
                </Grid>
              )}
              <Grid item container spacing={2} justify="center">
                <Button
                  type="submit"
                  className="submit button"
                  disabled={isSubmitting || !isValid}
                  onClick={handleSubmit}
                >
                  Reset password
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  )
}

export default withWidth()(ForgotPassword)
