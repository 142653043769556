import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'

const useGetPersonsByContact = () => {
  const [request, { loading, called, result }] = useFetch('Error fetching contacts')

  const getPersonsByContact = useCallback(
    ({ orgSlugName, groupId, email, phoneNumber }) =>
      request({
        url: `/applications/${orgSlugName}/groups/${groupId}/people`,
        config: {
          queryStringParameters: {
            email,
            phoneNumber,
          },
        },
        publicApi: true,
      }),
    [request],
  )

  return [getPersonsByContact, { loading: loading || !called, result }]
}

export default useGetPersonsByContact
