import React, { useEffect } from 'react'
import { useRouteMatch } from 'react-router'
import { useGetApplicationGroup } from '../hooks'
import Loading from '../../civic-champs-shared/core/Loading'
import GroupApplicationWizardForm from './valid/GroupApplicationWizardForm'
import { Error, mapErrorToProps } from 'core/Error'

const errorToProps = error => {
  const props = mapErrorToProps(error)
  const response = error?.response?.data
  return {
    ...props,
    description:
      response && response.statusCode === 403 && response.groupName ? (
        <p>
          Thank you for your interest in <strong>{response.groupName}!</strong> Applications are currently closed.
          Please reach out to your volunteer coordinator with any questions or concerns.
        </p>
      ) : (
        props.description
      ),
  }
}

export default function Applications(props) {
  const { currentUser } = props
  const { params } = useRouteMatch()
  const { orgSlugName, groupId } = params

  const [getApplicationGroup, { loading, result, error }] = useGetApplicationGroup()
  const { group, organization } = result || {}
  useEffect(() => {
    getApplicationGroup(orgSlugName, groupId)
  }, [getApplicationGroup, groupId, orgSlugName])

  if (loading) {
    return <Loading size={60} />
  }
  if (error) {
    return <Error {...errorToProps(error)} />
  }

  return (
    <GroupApplicationWizardForm
      group={group}
      organization={organization}
      orgSlugName={orgSlugName}
      currentUser={currentUser}
    />
  )
}
