import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { upperFirst } from 'lodash'

import { InvitationType } from 'civic-champs-shared/question-sets/types'
import format from 'civic-champs-shared/utils/format'
import { ContainedButton, OutlinedButton } from 'civic-champs-shared/core'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 70px',
    margin: '30px auto',
    maxWidth: '38%',
    border: '3px solid #4c8de8',
    borderRadius: '15px',
    [theme.breakpoints.down('md')]: {
      border: 0,
      maxWidth: 'unset',
      padding: '0 16px',
    },
  },
  buttons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

function DefaultRequirementsConfirmation({ invite, onConfirm }) {
  const firstSentence = useMemo(() => {
    const { invitationType, organization, group, registration } = invite
    const orgName = (organization && organization.name) || 'Civic Champs'
    const eventName = registration && registration.name
    if (invitationType === InvitationType.VOLUNTEERING && eventName) {
      return `${orgName} has invited you to begin volunteering at the event '${eventName}'.  However, they need to first gather some more information from you.`
    } else if (invitationType === InvitationType.VOLUNTEERING) {
      return `${orgName} has invited you to begin volunteering.  However, they need to first gather some more information from you.`
    } else if (invitationType === InvitationType.MENTORSHIP) {
      return `${orgName} has invited you to begin mentoring.  However, they need to first gather some more information from you.`
    } else if (invitationType === InvitationType.CUSTOM_GROUP) {
      return `${orgName} has invited you to join the group '${group.name}'.  However, they need to first gather some more information from you.`
    } else if (invitationType === InvitationType.MEMBERSHIP) {
      return `${orgName} has invited you to become a member.  However, they need to first gather some more information from you.`
    } else {
      return `${orgName} has requested that please provide some additional information in order to continue using your Civic Champs account.`
    }
  }, [invite])

  return (
    <div className="invite-header">
      <h2>Hi {upperFirst(invite.recipientGivenName)}</h2>
      <p style={{ textAlign: 'center' }}>
        {firstSentence} Please complete their short questionnaire(s) and we will get you connected to your account.
      </p>
      <Button className="submit button" onClick={onConfirm}>
        Complete Questionnaires
      </Button>
    </div>
  )
}

function MessageRequirementsConfirmation({ invite, onConfirm }) {
  const classes = useStyles()

  const [confirmed, setConfirmed] = useState(false)
  const [declined, setDeclined] = useState(false)

  const { organization, person } = invite

  const formattedName = format.name(person)

  return declined ? (
    <div className={classes.container}>
      <h1 style={{ textAlign: 'center' }}>{organization.name}'s contact information may be out of date</h1>
      <p>
        Looks like that questionnaire was sent to you by mistake!
        <br />
        Please rich out to the sending organization, to let them know to update their contact information.
      </p>
    </div>
  ) : (
    <div className={classes.container}>
      <h1 style={{ textAlign: 'center' }}>Please confirm your identity</h1>
      <p>
        {organization.name} has requested {formattedName} fill out their questionnaire(s).
      </p>
      <p>
        <FormControlLabel
          control={
            <Checkbox color="primary" value={confirmed} onChange={event => setConfirmed(event.target.checked)} />
          }
          label={`I am ${formattedName}`}
        />
      </p>
      <div className={classes.buttons}>
        <OutlinedButton onClick={() => setDeclined(true)}>No, that is not me</OutlinedButton>
        <ContainedButton type="submit" disabled={!confirmed} onClick={onConfirm}>
          Next
        </ContainedButton>
      </div>
    </div>
  )
}

const componentByInvitationTypeLookup = {
  [InvitationType.MESSAGE_QUESTIONNAIRE]: MessageRequirementsConfirmation,
}

export function FullfillRequirementsConfirmation(props) {
  const { invite } = props
  const Component = componentByInvitationTypeLookup[invite.invitationType] || DefaultRequirementsConfirmation

  return <Component {...props} />
}

export default FullfillRequirementsConfirmation
