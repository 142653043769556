import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { resetPasswordSchema } from '../../schemas'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import { PasswordField } from 'civic-champs-shared/formik/components'

function ChangePassword(props) {
  const { onSubmit, user, classes } = props
  const { email, phoneNumber } = user

  return (
    <>
      <div className="invite-header">
        <p style={{ textAlign: 'center' }}>
          You should have been sent a verification code. Please check your {email ? 'email' : 'phone'}
        </p>
      </div>

      <Formik
        initialValues={{
          contact: email || phoneNumber,
          code: '',
          password: '',
          passwordConfirm: '',
        }}
        validationSchema={resetPasswordSchema}
        onSubmit={values => onSubmit(resetPasswordSchema.cast(values))}
        validateOnBlur
      >
        {({ dirty, isValid }) => (
          <Form>
            <Grid container spacing={2} direction="column" justify="center" alignItems="center">
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    component={TextField}
                    name="code"
                    label="Verification Code"
                    className={classes.input}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    component={PasswordField}
                    name="password"
                    variant="outlined"
                    label="Password"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Grid item>
                  <Field
                    component={PasswordField}
                    name="passwordConfirm"
                    variant="outlined"
                    label="Confirm Password"
                    className={classes.input}
                  />
                </Grid>
              </Grid>
              <Grid item container spacing={2} justify="center">
                <Button variant="contained" disabled={!dirty || !isValid} type="submit" className="submit button">
                  Change password
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ChangePassword
