import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    borderRadius: '20px',
    border: '5px solid #4985DF',
    background: '#FFF',
    padding: '50px 90px',
    display: 'flex',
    flexDirection: 'column',
    width: '485px',
    gap: '25px',
    '& h1': {
      margin: 0,
      padding: 0,
      fontFamily: 'Poppins',
      fontSize: '32px',
      fontWeight: '500',
      lineHeight: '40px',
    },
    '& p, div': {
      margin: 0,
      padding: 0,
      fontFamily: 'Nunito',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
      '& strong': {
        fontWeight: 700,
      },
    },
  },
})

export const mapErrorToProps = (error: any) => ({
  title: error?.response?.data?.error || 'Error Occurred',
  description: error?.response?.data?.message || 'Unknown error',
})

export const Error = ({ title, description }: { title: string; description: string | React.ReactNode }) => {
  const classes = useStyles()
  return (
    <div className={classes.wrapper}>
      <div className={classes.error}>
        <h1>{title}</h1>
        <div>{description}</div>
      </div>
    </div>
  )
}
