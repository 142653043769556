import { useFetch } from "civic-champs-shared/api/hooks";
import { useCallback } from "react"

const useGetInvite = () => {
    const [request, { loading, called, result }] = useFetch('Error fetching invitation')

    const getInvite = useCallback( 
        (token) => request({ url: `/invitations/${token}/fetch` }),
        [request]
    )

    return [getInvite, { loading: loading || !called, result }]
}

export default useGetInvite;
