import React, { useMemo } from 'react'
import { Grid, Button } from '@material-ui/core'
import { isAndroid, isIOS } from 'react-device-detect'
import { InvitationType } from '../../../civic-champs-shared/question-sets/types'
import { DesktopQRCodes } from './DesktopQRCodes'
import { LinkButton } from '../../../common/components/LinkButton'
import appleStoreQRForCC from '../../../images/cc-apple-store-app-qr-code.png'
import googleStoreQRForCC from '../../../images/cc-google-play-store-app-qr-code.png'
import appleStoreQRForMW from '../../../images/mw-apple-store-app-qr-code.png'
import googleStoreQRForMW from '../../../images/mw-google-play-store-app-qr-code.png'

const {
  REACT_APP_CC_APPLE_STORE_LINK,
  REACT_APP_CC_ANDROID_STORE_LINK,
  REACT_APP_MW_APPLE_STORE_LINK,
  REACT_APP_MW_ANDROID_STORE_LINK,
  REACT_APP_VOLUNTEER_SITE,
} = process.env

export default function Welcome(props) {
  const { children, invite } = props
  const isHelpingHands =
    invite &&
    [InvitationType.TaskRequester, InvitationType.TaskVolunteer, InvitationType.TaskRecipient].includes(
      invite.invitationType,
    )

  const { organization } = invite
  const { website: baseOrgWebsite, isMentoringCustomer } = organization
  const finalOrgWebsite =
    baseOrgWebsite && baseOrgWebsite.indexOf('://') === -1 ? 'http://' + baseOrgWebsite : baseOrgWebsite
  const helpingHandsLink = REACT_APP_VOLUNTEER_SITE || 'https://volunteer.civicchamps.com'
  const appleAppStoreLink = isMentoringCustomer ? REACT_APP_MW_APPLE_STORE_LINK : REACT_APP_CC_APPLE_STORE_LINK
  const androidAppStoreLink = isMentoringCustomer ? REACT_APP_MW_ANDROID_STORE_LINK : REACT_APP_CC_ANDROID_STORE_LINK
  const appleAppStoreQR = isMentoringCustomer ? appleStoreQRForMW : appleStoreQRForCC
  const googleAppStoreQR = isMentoringCustomer ? googleStoreQRForMW : googleStoreQRForCC

  const linkComponent = useMemo(() => {
    if (isHelpingHands) {
      return <LinkButton link={helpingHandsLink} label="Get Started" />
    } else if (isIOS) {
      return <LinkButton link={appleAppStoreLink} description="Get the app by following the link below." />
    } else if (isAndroid) {
      return <LinkButton link={androidAppStoreLink} description="Get the app by following the link below." />
    } else {
      return <DesktopQRCodes appleAppStoreQR={appleAppStoreQR} googleAppStoreQR={googleAppStoreQR} />
    }
  }, [androidAppStoreLink, appleAppStoreLink, appleAppStoreQR, googleAppStoreQR, helpingHandsLink, isHelpingHands])

  return (
    <>
      {children}
      <Grid container direction="column" justify="center" alignItems="center">
        {linkComponent}
        {finalOrgWebsite && !isHelpingHands && (
          <Button className="submit button" onClick={() => (window.location.href = finalOrgWebsite)}>
            Got It!
          </Button>
        )}
      </Grid>
    </>
  )
}
