import React, { useEffect } from 'react'
import { QuestionAnsweringForm } from 'civic-champs-shared/question-sets/components/question-set-answering/QuestionAnsweringForm'
import Loading from 'civic-champs-shared/core/Loading'
import { useFetchQuestionnairePreview } from 'question-preview/hooks/useFetchQuestionnairePreview'

export default function QuestionnairePreview(route: any) {
  const {
    match: {
      params: { uuid },
    },
  } = route

  const [fetch, { loading, result: questionSet, called, error }] = useFetchQuestionnairePreview()

  useEffect(() => {
    fetch(uuid)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!called || loading ? (
        <Loading />
      ) : error ? (
        <h1 style={{ color: '#BA1B1B' }}>{error.message}</h1>
      ) : (
        <QuestionAnsweringForm
          onSubmit={window.close}
          questionSet={questionSet}
          onCancel={window.close}
          onEdit={window.close}
          required={true}
          heading={questionSet.name}
          readonly={false}
          organizationId={questionSet.organization!.id as number}
        />
      )}
    </>
  )
}
