import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCallback } from 'react'

const useUpdateProfile = invite => {
  const [request, status] = useFetch('Error accepting invitation')
  const secureToken = invite && invite.secureToken
  const updateProfile = useCallback(
    profile => {
      return request({
        method: 'post',
        url: `/invitations/${secureToken}/verify`,
        config: {
          body: profile,
        },
      })
    },
    [secureToken, request],
  )

  return [updateProfile, status]
}

export default useUpdateProfile
