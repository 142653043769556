import { useState, useEffect, useMemo, useCallback } from 'react'
import { Hub } from '@aws-amplify/core'
import { Auth } from 'aws-amplify'
import { useFetchPerson } from 'civic-champs-shared/question-sets/hooks'
import { getCognitoKeys, clearCognitoKeys } from 'civic-champs-shared/auth/utils/cognito'
import { useAsyncEffect } from 'use-async-effect'

const SESSION_STORAGE_EVENT_KEY = 'SKIP_NEXT_EVENT'
export const skipNextAuthEvent = eventType => sessionStorage.setItem(SESSION_STORAGE_EVENT_KEY, eventType)

function useUserStatus() {
  const [user, setUser] = useState(null)
  const [loggedIn, setLoggedIn] = useState(!!Auth.user)
  const [loading, setLoading] = useState(true)
  const [fetchPerson] = useFetchPerson()

  const logOut = useCallback(() => {
    setLoggedIn(false)
    setUser(null)
    setLoading(false)
    clearCognitoKeys()
  }, [])

  const login = useCallback(() => {
    setLoading(true)
    fetchPerson()
      .then(res => {
        setUser(res)
        setLoggedIn(true)
        setLoading(false)
      })
      .catch(() => {
        logOut()
      })
  }, [fetchPerson, logOut])

  // attempt to login in case there is a saved localStorage token
  useAsyncEffect(async () => {
    if ((!loggedIn || !user) && getCognitoKeys().length) {
      try {
        await Auth.currentAuthenticatedUser()
        await login()
      } catch {
        await logOut()
      }
    } else {
      setLoading(false)
    }
  }, [])

  // listen for login or logout events
  useEffect(() => {
    const updateLoggedInStatus = async data => {
      const { payload } = data
      if (payload.event === sessionStorage.getItem(SESSION_STORAGE_EVENT_KEY)) {
        return sessionStorage.removeItem(SESSION_STORAGE_EVENT_KEY)
      }
      if (payload.event === 'signIn') {
        login()
      } else if (payload.event === 'signOut') {
        logOut()
      }
    }
    Hub.listen('auth', updateLoggedInStatus)
    return () => Hub.remove('auth', updateLoggedInStatus) // cleanup
  }, [logOut, login])

  return useMemo(() => ({ user, loading, loggedIn }), [user, loading, loggedIn])
}

export default useUserStatus
