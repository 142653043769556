import { isEmpty } from 'lodash'
import { useCallback } from "react"
import { Auth } from 'aws-amplify'
import { v4 as uuid } from 'uuid';
import { useErrorNotification } from "civic-champs-shared/api/hooks"


const useSignUp = (invite) => {
    const { secureToken = '' } = invite || {}
    const showError = useErrorNotification()

    const signUp = useCallback(
      async values => {
        const { id, givenName, familyName, email, phoneNumber, password, emailVerified, phoneNumberVerified } = values
        const clientMetadata = {
          secureToken,
          personId: String(id),
        }

        const stage = process.env.REACT_APP_STAGE
        if (stage === 'dev' || stage === 'development') {
          const endpoint = process.env.REACT_APP_NGROK_API_URL
          if (!isEmpty(endpoint)) {
            clientMetadata.endpointOverride = endpoint
          }
        }

        try {
          const username = uuid()

          const phoneNumberKey = phoneNumberVerified && !emailVerified ? 'phone_number' : 'custom:alt_phone_number'
          await Auth.signUp({
            username,
            password,
            attributes: {
              ...(email ? { email } : {}),
              ...(phoneNumber ? { [phoneNumberKey]: phoneNumber } : {}),
              given_name: givenName,
              family_name: familyName,
            },
            clientMetadata,
          })
        } catch (error) {
          showError('Error creating account.  Please refresh the page and try again', error)
          throw error
        }
      },
      [secureToken, showError],
    )

    return signUp
}

export default useSignUp
