import React from 'react'
import Welcome from './Welcome'
import * as _ from 'lodash'

export default function AllSet(props) {
    const invite = _.get(props, 'history.location.state.invite')
    return (
        <>
            <Welcome invite={invite}>
                <h1 style={{ textAlign: 'center' }}>You are all set! You have completed all required questionnaires and waivers.</h1>
            </Welcome>
        </>
    )
}
