import React from 'react'
import { useStepStyles } from 'invite/hooks/useStepStyles'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import { makeStyles } from '@material-ui/core/styles'
import StyledOnboardingCheckboxField from 'civic-champs-shared/formik/components/StyledOnboardingCheckboxField'

const verticalSeparatorStyle = {
  borderLeft: '2px solid #EEF3F6',
  width: 0,
}
const horizontalSeparatorStyle = {
  borderTop: '2px solid #EEF3F6',
  height: 0,
  width: '100%',
}
const wrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  margin: '0 auto',
}

const wrapperSmallStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
}

const getTz = tz =>
  ({
    'America/New_York': 'Eastern Time',
    'America/Chicago': 'Central Time',
    'America/Denver': 'Mountain Time',
    'America/Phoenix': 'Mountain Standard Time',
    'America/Los_Angeles': 'Pacific Time',
    'America/Anchorage': 'Alaska Time',
    'Pacific/Honolulu': 'Hawaii Time',
  }[tz])

const useStyles = makeStyles(theme => ({
  page: {
    width: '320px',
    '& h3': {
      fontFamily: 'Nunito, sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '22px',
      lineHeight: '28px',
      color: '#000000',
      marginBottom: '20px',
      [theme.breakpoints.down(600)]: {
        fontWeight: 700,
        fontSize: '16px',
        lineHeight: '24px',
        letterSpacing: '0.1px',
      },
    },
  },
  wrapper: ({ initialStep }) =>
    initialStep === 0
      ? {
          width: '1040px',
          ...wrapperStyle,
          '& .review-separator': verticalSeparatorStyle,
          [theme.breakpoints.down(1040)]: {
            width: '320px',
            ...wrapperSmallStyle,
            '& .review-separator': horizontalSeparatorStyle,
          },
        }
      : {
          width: '680px',
          ...wrapperStyle,
          '& .review-separator': verticalSeparatorStyle,
          [theme.breakpoints.down(1040)]: {
            width: '680px',
            ...wrapperSmallStyle,
            '& .review-separator': horizontalSeparatorStyle,
          },
        },
}))

function Review(props) {
  const { onSubmit, onBack, profile, invite, isSubmitting, initialStep, organization } = props
  const classes = useStepStyles()
  const additionalClasses = useStyles({ initialStep })
  const additionalContactTitle = invite.method === 'email' ? 'Mobile Phone' : 'Email'
  const additionalContactValue = invite.method === 'email' ? profile.phoneNumber : profile.email
  return (
    <>
      <div className={additionalClasses.wrapper}>
        {initialStep === 0 && (
          <>
            <div className={additionalClasses.page}>
              <h3>Organization</h3>
              <label className={classes.title}>Name</label>
              <div className={classes.field}>
                <p>{organization.name}</p>
              </div>
              {organization.website && (
                <>
                  <label className={classes.title}>Website</label>
                  <div className={classes.field}>
                    <p>{organization.website}</p>
                  </div>
                </>
              )}
              <label className={classes.title}>Time Zone</label>
              <div className={classes.field}>
                <p>{getTz(organization.timeZone)}</p>
              </div>
              <label className={classes.title}>Address</label>
              <div className={classes.field} style={{ marginBottom: '30px' }}>
                <p>{organization.address.line1}</p>
              </div>
            </div>
            <div className="review-separator"></div>
          </>
        )}
        <div className={additionalClasses.page}>
          <h3>Log-in</h3>
          <label className={classes.title}>{invite.method === 'email' ? 'Email' : 'Mobile Phone'}</label>
          <div className={classes.field}>
            <p>{invite.method === 'email' ? invite.person.email : invite.person.phoneNumber}</p>
          </div>
          <label className={classes.title}>Password</label>
          <div className={classes.field}>
            <p>*********</p>
          </div>
          <label className={classes.title}>Confirm Password</label>
          <div className={classes.field}>
            <p>*********</p>
          </div>
        </div>
        <div className="review-separator"></div>
        <div className={additionalClasses.page}>
          <h3>Additional Information</h3>
          <label className={classes.title}>First Name</label>
          <div className={classes.field}>
            <p>{profile.givenName}</p>
          </div>
          <label className={classes.title}>Last Name</label>
          <div className={classes.field}>
            <p>{profile.familyName}</p>
          </div>
          {additionalContactValue && (
            <>
              <label className={classes.title}>{additionalContactTitle}</label>
              <div className={classes.field}>
                <p>{additionalContactValue}</p>
              </div>
            </>
          )}
          {profile.homePhoneNumber && (
            <>
              <label className={classes.title}>Home Phone</label>
              <div className={classes.field}>
                <p>{profile.homePhoneNumber}</p>
              </div>
            </>
          )}
          <div className={classes.field} style={{ marginBottom: '30px' }}>
            <StyledOnboardingCheckboxField
              disabled
              field={{ value: true, name: 'x' }}
              form={{ errors: [] }}
              name="olderThanThirteen"
              label="I am 13 years or older"
            />
          </div>
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.buttons}>
          <ContainedButton type="submit" disabled={isSubmitting} onClick={onSubmit}>
            Create Account
          </ContainedButton>
          {onBack && (
            <OutlinedButton onClick={onBack} disabled={isSubmitting}>
              Back
            </OutlinedButton>
          )}
        </div>
      </div>
    </>
  )
}

export default Review
