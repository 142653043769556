import React, { useState, useCallback, useEffect } from 'react'
import Welcome from './Welcome'
import { get } from 'lodash'
import { useHistory } from 'react-router'

import { RequirementsModal } from 'civic-champs-shared/question-sets/components'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { invitationTypeToActionLookup } from 'civic-champs-shared/question-sets/types'

import { useAcceptOrRejectInvite, useUpdateProfile } from 'invite/hooks'
import { FullfillRequirementsConfirmation } from './FullfillRequirementsConfirmation'

export default function InviteAlreadyAccepted({ invite }) {
  const [inProgress, setInProgress] = useState(true)
  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)
  const history = useHistory()
  const [updateProfile] = useUpdateProfile(invite)
  const [acceptOrRejectInvite] = useAcceptOrRejectInvite(invite)

  const handleRequirements = useCallback(async () => {
    if (!invite) {
      return null
    }

    const { person, filters } = invite
    try {
      const done = await fulfillRequirementsPrompt({
        person: person,
        filters: filters || {
          personId: person.id,
          invitationToken: invite.secureToken,
          action: get(invitationTypeToActionLookup, invite.invitationType, 'user_sign_up'),
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
          excludeCredentials: false,
        },
        isAdmin: false,
        continueEvenIfError: false,
      })
      if (done) {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage({ type: 'onboarding-complete' })
        }
        history.push({
          pathname: '/all-set',
          state: { invite },
        })

        await acceptOrRejectInvite('accept')
        setInProgress(false)
      }
    } catch (error) {}
  }, [invite, fulfillRequirementsPrompt, history, acceptOrRejectInvite])

  useEffect(() => {
    async function verifyContacts() {
      await updateProfile(invite.person)
    }
    verifyContacts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (inProgress) {
    return <FullfillRequirementsConfirmation invite={invite} onConfirm={handleRequirements} />
  }

  return (
    <>
      <Welcome invite={invite}>
        <h1 style={{ textAlign: 'center' }}>
          You are all set! You have completed all required questionnaires and waivers.
        </h1>
      </Welcome>
    </>
  )
}
