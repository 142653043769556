import { makeStyles } from '@material-ui/core/styles'

export const useStepStyles = makeStyles({
  container: {
    width: '100%',
    maxWidth: '462px',
    display: 'block',
    margin: '0 auto',
    paddingBottom: '23px',
  },
  title: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#0F5DB5',
    width: '100%',
    display: 'block',
    float: 'left',
    textAlign: 'left',
  },
  field: {
    width: '100%',
    display: 'block',
    float: 'left',
    '& > p': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: '#44474F',
      margin: '10px 0 17px 10px',
    },
    '& > div': {
      marginBottom: '20px',
    },
    '& > div.marginless': {
      marginBottom: '20px',
    },
  },
  buttons: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
  },
})
