import React from 'react'
import { stepOneSchema } from '../../schemas'
import { Field, Formik } from 'formik'
import { cloneDeep } from 'lodash'
import { useStepStyles } from 'invite/hooks/useStepStyles'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import StyledOutlinedPasswordField from 'civic-champs-shared/formik/components/StyledOutlinedPasswordField'

function StepOne(props) {
  const { invite, onSubmit, children, password, onBack } = props
  const classes = useStepStyles()
  return (
    <>
      {children}
      <Formik
        initialValues={{
          password: cloneDeep(password),
          passwordConfirm: cloneDeep(password),
        }}
        validationSchema={stepOneSchema}
        onSubmit={values => onSubmit(stepOneSchema.cast(values))}
        validateOnBlur={true}
        validateOnChange={false}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <form>
            <div className={classes.container}>
              <label className={classes.title}>{invite.method === 'email' ? 'Email' : 'Phone'}</label>
              <div className={classes.field}>
                <p>{invite.method === 'email' ? invite.person.email : invite.person.phoneNumber}</p>
              </div>
              <label className={classes.title}>Password</label>
              <div className={classes.field}>
                <Field
                  fullWidth
                  component={StyledOutlinedPasswordField}
                  name="password"
                  variant="outlined"
                  marginless
                />
              </div>
              <label className={classes.title}>Confirm Password</label>
              <div className={classes.field} style={{ marginBottom: '30px' }}>
                <Field
                  fullWidth
                  component={StyledOutlinedPasswordField}
                  name="passwordConfirm"
                  variant="outlined"
                  marginless
                />
              </div>
              <div className={classes.buttons}>
                <ContainedButton
                  type="submit"
                  disabled={isSubmitting || !values.password || !values.passwordConfirm}
                  onClick={handleSubmit}
                >
                  Next
                </ContainedButton>
                {onBack && (
                  <OutlinedButton onClick={onBack} disabled={isSubmitting}>
                    Back
                  </OutlinedButton>
                )}
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default StepOne
