import React from 'react'
import InviteForm from "./InviteForm";
import { upperFirst } from "lodash";

export default function TaskVolunteerInvite(props) {
    const { invite } = props
    return (
        <InviteForm invite={invite}>
            <div className="invite-header">
                <h2>Hi {upperFirst(invite.recipientGivenName)}</h2>
                <p style={{textAlign: 'center'}}>{invite.organization.name} has added you as a Helping Hands VOLUNTEER on the Civic Champs platform.  Please complete our simple sign up below and we will get you connected to your account.</p>
            </div>
        </InviteForm>
    )
}
