import React from 'react'
import InviteForm from './InviteForm'
import { upperFirst } from 'lodash'

export default function SelfServePurchaseInvite(props) {
  const { invite } = props
  return (
    <InviteForm invite={invite} initialStep={0}>
      <div className="invite-header">
        <h2>Hi {upperFirst(invite.recipientGivenName)}</h2>
        <p style={{ textAlign: 'center' }}>Please fill out the following information to get your account set up.</p>
      </div>
    </InviteForm>
  )
}
