import { Button, Grid } from '@material-ui/core'
import React from 'react'

export const LinkButton = props => {
  const { link, description, label = 'Download the App' } = props
  return (
    <Grid item xs={9} container direction="column" justify="center" alignItems="center" className="invite-header">
      {description && <p>{description}</p>}
      <Button className="submit button" onClick={() => (window.location.href = link)}>
        {label}
      </Button>
    </Grid>
  )
}
