import { useCallback, useMemo } from 'react'
import { useFetch } from '../../api/hooks'
import { useSuccessNotification } from '../../api/hooks'

export const useSubmitFeedback = (onSuccess: (res: any) => any) => {
  // @ts-ignore
  const [request, { loading, error, result }] = useFetch(
    'Error submitting feedback.  Please refresh the page to try again',
  )
  const showSuccess = useSuccessNotification()
  const submitFeedback = useCallback(
    ({ questionSet, meetingId, type, rating, notes }) => {
      // @ts-ignore
      return request({
        method: 'put',
        url: `/mentorship-meetings/${meetingId}/mentorship-feedback/${type}`,
        config: {
          body: {
            notes,
            rating: typeof rating !== 'undefined' ? !!rating : rating,
            answerSet: questionSet,
          },
        },
        onSuccess: (res: any) => {
          showSuccess('Successfully saved questionnaire')
          onSuccess && onSuccess(res)
        },
      })
    },
    [request, showSuccess, onSuccess],
  )

  return useMemo(
    () => [
      submitFeedback,
      {
        result,
        loading,
        error,
      },
    ],
    [result, loading, error, submitFeedback],
  )
}
