import { Auth } from 'aws-amplify'

export const getAuthorizationToken = async () => {
  try {
    const session = await Auth.currentSession()
    return session.getIdToken().getJwtToken()
  } catch {
    return localStorage.getItem('secureToken')
  }
}
