import React from 'react'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Stepper from './Stepper'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

export const useStyles = makeStyles(theme => ({
  marginTop: {
    marginTop: '36px',
  },
  root: {
    [theme.breakpoints.down(600)]: {
      justifyContent: 'center',
    },
  },
}))

interface StepContainerProps {
  activeStep: number
  steps: string[]
  children: React.ReactNode
}

export function StepContainer({ activeStep, steps, children }: StepContainerProps) {
  const classes = useStyles()
  return (
    <Container maxWidth="md">
      {steps.length > 1 && (
        <Grid container direction="row" alignItems="flex-start" justify="center">
          <Stepper activeStep={activeStep} steps={steps} />
        </Grid>
      )}
      <Box className={cn({ [classes.marginTop]: steps.length === 1 })}>{children}</Box>
    </Container>
  )
}

export default StepContainer
